<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" text @click="refresh" v-on="on">
              <v-icon dark size="25">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="appointmentlist"
              :items-per-page="10"
              :loading="loading"
              :sort-by="['id']"
              :sort-desc="[true]"
              loader-height="1"
          >
            <template v-slot:item.subscriberName="{ item }">
              {{ item.subscriberName | capitalize }}
            </template>
            <template v-slot:item.cost="{ item }">
              {{ currency(item.cost) }}
            </template>
            <template v-slot:item.schedule_date="{ item }">
              {{ item.schedule_date | myDate }} @ {{ item.time_start | myTime }}
            </template>
            <template v-slot:item.time_start="{ item }">
              {{ item.time_start | myTime }}
            </template>
            <template v-slot:item.time_end="{ item }">
              {{ item.time_end | myTime }}
            </template>

            <template v-slot:item.paid="{ item }">
              <v-btn
                  :disabled="isAcceptedAndPaid(item.accepted, item.paid)"
                  color="primary"
                  small
                  text
                  @click="checkout(item.id, item.cost)"
              >
                {{ item.paid ? "Paid" : "Pay Now" }}
              </v-btn>
            </template>

            <template v-slot:item.accepted="{ item }">
              <v-icon :color="item.accepted ? 'primary' : 'error'"
              >{{ item.accepted ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                  color="primary"
                  text
                  x-small
                  @click.stop="$set(detailDialog, item.id, true)"
              >
                detail
              </v-btn>
              <AppointmentDetail
                  v-if="detailDialog[item.id]"
                  :detailDialog="detailDialog[item.id]"
                  :myId="item.id"
                  @closeDetail="closeDetail"
              />

              <v-btn
                  :disabled="checkDisabled(item.accepted)"
                  color="success"
                  text
                  x-small
                  @click.stop="$set(updateDialog, item.patient_requestid, true)"
              >
                edit
              </v-btn>
              <RequestUpdate
                  v-if="updateDialog[item.patient_requestid]"
                  :myId="item.patient_requestid"
                  :updateDialog="updateDialog[item.patient_requestid]"
                  @closeUpdate="closeUpdate"
                  @submitUpdate="submitUpdate"
              />

              <v-btn
                  :disabled="item.accepted"
                  color="error"
                  text
                  x-small
                  @click.stop="$set(removeDialog, item.patient_requestid, true)"
              >
                remove
              </v-btn>
              <RequestRemove
                  v-if="removeDialog[item.patient_requestid]"
                  :ends="item.time_end"
                  :medavId="item.id"
                  :myId="item.patient_requestid"
                  :removeDialog="removeDialog[item.patient_requestid]"
                  :selectedDate="item.date_start"
                  :start="item.time_start"
                  @closeDelete="closeDelete"
                  @submitDelete="submitDelete"
              />

              <v-btn
                  :to="{
                  name: 'patient.appointments.more',
                  params: {
                    id: _encode(item.id),
                    date: _encode(item.schedule_date),
                    start: _encode(item.time_start),
                    loc: _encode(item.meeting_location),
                    end: _encode(item.time_end),
                  },
                }"
                  color="primary"
                  text
                  x-small
              >
                more info
              </v-btn>

              <v-btn
                  :to="{
                  name: 'patient.appointments.evidence',
                  params: {
                    id: _encode(item.id),
                    date: _encode(item.schedule_date),
                    start: _encode(item.time_start),
                    loc: _encode(item.meeting_location),
                    end: _encode(item.time_end),
                  },
                }"
                  color="success"
                  text
                  x-small
              >
                evidence
              </v-btn>

              <v-badge bordered color="primary" icon="mdi-arrow-right" overlap>
                <v-btn
                    :disabled="checkDisabled(item.accepted, item.done)"
                    :to="{
                    name: 'patient.appointments.followup',
                    params: {
                      // :id/:subscriber/:date
                      id: _encode(item.id),
                      subscriber: _encode(item.subscriberName),
                      date: _encode(item.schedule_date),
                    },
                  }"
                    color="primary"
                    style="padding-right: 18px"
                    x-small
                >
                  followup
                </v-btn>
              </v-badge>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import AppointmentDetail from "../../components/appointments/schedules/detail.vue";
import RequestUpdate from "../../components/appointments/request/update.vue";
import RequestRemove from "../../components/appointments/request/deletion.vue";
import Util from "../../mixins/utils";
import moment from "moment";

export default {
  components: {AppointmentDetail, RequestUpdate, RequestRemove},
  mixins: [Util, WinScroll("position")],

  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: {name: "patient.dash"},
        },
        {
          exact: true,
          link: true,
          text: "Appointment",
          to: {name: "patient.appointments"},
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      detailDialog: {},
      removeDialog: {},
      paymentDialog: {},
      appointmentlist: [],
      updateDialog: {},
      disabled: false,
      loading: false,
      headers: [
        {text: "provider", value: "subscriberName"},
        {text: "date", value: "schedule_date"},
        {text: "accepted", value: "accepted", align: "center"},
        {text: "cost", value: "cost"},
        {text: "payment", value: "paid", align: "center"},
        {text: "actions", value: "id", align: "center"},
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    isPast(d) {
      let date = moment(d).format("lll");
      return moment(date).isBefore();
      // console.log("is before: ", moment(date).isBefore());
      // if (date > new Date()) {
      //   console.log("greater");
      // } else {
      //   console.log("less");
      // }

      // console.log("date:", date);
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
          .appointment()
          .then((response) => {
            console.log('appointment', response.data);
            if (response.status === 200) {
              this.appointmentlist = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    paid() {
      Restful.medics.patient
          .appointment()
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.appointmentlist = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
    isAcceptedAndPaid(accepted, paid) {
      if (accepted === false || paid === true) {
        return true;
      } else {
        return false;
      }
    },
    refresh() {
      this.list();
    },
    closeDetail(p) {
      this.$set(this.detailDialog, p.id, p.state);
    },
    menuLink() {
      this.$emit("sidebar");
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patient.request
          .update(p.id, p.data)
          .then((response) => {
            console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
          });
      this.$set(this.updateDialog, p.id, p.state);
    },
    checkDisabled(accepted, done) {
      console.log("accepted:", accepted);
      console.log("done:", done);
      if (accepted === true) {
        if (done === undefined || done === false) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    closeDelete(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitDelete(p) {
      this.snack.bar = true;
      console.log("id:", p.id);
      console.log("medic:", p.medic);
      // delete(availaid, reqid)
      Restful.medics.patient.request
          .delete(p.medic, p.id)
          .then((response) => {
            console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
          });
      this.$set(this.removeDialog, p.id, p.state);
    },
    checkout(myId, cost) {
      let price = cost;
      let medicsAvailableId = myId;
      let type = "provider";
      let service_type = "medics";
      let id = 0;
      let data = {id, price, medicsAvailableId, type, service_type};
      // encode
      let encode = this._encode(JSON.stringify(data));
      this.$router.push({name: "pay.now", params: {id: encode}});
    },
  },
};
</script>
